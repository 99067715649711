import { template as template_71757b8624f149a7aa30b3ecd7ef63cb } from "@ember/template-compiler";
const WelcomeHeader = template_71757b8624f149a7aa30b3ecd7ef63cb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
