import { template as template_d5673609f7d648a8b5e5254bad0915e5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d5673609f7d648a8b5e5254bad0915e5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
