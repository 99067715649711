import { template as template_ad56c8d48af34cc78802d46fe8f7071e } from "@ember/template-compiler";
const FKLabel = template_ad56c8d48af34cc78802d46fe8f7071e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
