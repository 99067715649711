import { template as template_8e34aa4fe7204c659453bdf19a2ce4ac } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
const GeneratedInviteLink = template_8e34aa4fe7204c659453bdf19a2ce4ac(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
