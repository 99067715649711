import { template as template_b4eb7a4f6f904ecea65ec2757828f333 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b4eb7a4f6f904ecea65ec2757828f333(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
