import { template as template_0904e929aa8144a2895f11b983e91629 } from "@ember/template-compiler";
const FKText = template_0904e929aa8144a2895f11b983e91629(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
